import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Helmet from 'react-helmet';
import Footer from '../footer/footer';
import Navbar from '../navbar/navbar';
import Link from '../link/link';

// Helmet does not support React.Fragment, therefore the below syntax
// see: https://github.com/nfl/react-helmet/issues/342
const Meta = ({ title, description, image }) => (
    <Helmet>
        {title && <title>{title}</title>}
        {title && <meta name="og:title" content={title}></meta>}
        {description && <meta name="description" content={description}></meta>}
        {description && <meta name="og:description" content={description}></meta>}
        {image && <meta name="image" content={image}></meta>}
        {image && <meta name="og:image" content={image}></meta>}
    </Helmet>
);

export default props => {
    const titleSuffix = 'Oster Dach + Holzbau GmbH';
    const titleDivider = ' - ';
    return (
        <React.Fragment>
            <Meta
                title={`${(props.title === null || props.title === undefined) ? '' : props.title + titleDivider}${titleSuffix}`}
                description={props.description}
                image={props.image}
            />
            <Navbar/>
                {props.children}
            <Footer/>
            <CookieConsent
                location="bottom"
                buttonText="Okay"
                cookieName="ostr-cookie-consent"
                buttonClasses="my-2"
                style={{ background: '#eaeaea', color: '#111' }}
                buttonStyle={{ background: '#B2CA38', color: '#fff', fontSize: '16px', padding: '4px 12px', alignSelf: 'center' }}
                expires={365}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-11 px-0 mx-0">
                            Wir nutzen Cookies, um anonyme Nutzungsdaten zu erfassen und die Nutzererfahrung zu verbessern.{" "}
                            <Link className="unstyled" to="/datenschutz">Mehr erfahren.</Link>
                        </div>
                    </div>
                </div>
            </CookieConsent>
        </React.Fragment>
    );
}
